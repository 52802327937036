import React from 'react'
import { useBrands, useBrandsRequest } from '../../../stores/brands'
import { Element, Form, Heading, } from 'react-bulma-components'
import { ResourceStatusHandler } from '../../../stores/utils'
import { useProducts } from '../../../stores/products'
import {
  useCategories,
  useCategoriesForFilters,
  useCategoriesRequest,
} from '../../../stores/categories'
import { useI18next, useTranslation } from 'gatsby-plugin-react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCaretDown,
  faCaretRight,
} from '@fortawesome/free-solid-svg-icons'
import { sortBy } from 'lodash'

const CheckboxesFilters = ({
  options = [],
  values = [],
  onChange,
  level = 0,
}) => {
  const onOptionChange = value => {
    const indexOfValue = values.indexOf(value)
    if (indexOfValue === -1) {
      onChange([...values, value])
    } else {
      const currentValues = [...values]
      currentValues.splice(indexOfValue, 1)
      onChange(currentValues)
    }
  }

  const [showChildrends, setShowChildrens] = React.useState([])
  const showOrHideChildren = value => {
    if (showChildrends.includes(value)) {
      setShowChildrens(showChildrends.filter(v => v !== value))
    } else setShowChildrens([...showChildrends, value])
  }

  return options.map(option => {
    const isShowed = showChildrends.includes(option.value)
    const childrensSelectedCount = option.childrens
      ? option.childrens.filter(c => values.includes(c.value)).length
      : 0
    return (
      <Form.Control key={option.value} className='mb-1'>
        <Form.Checkbox
          onChange={() => onOptionChange(option.value)}
          checked={values.includes(option.value)}
        >
          {option.label}
        </Form.Checkbox>
        {option.childrens && (
          <>
            <FontAwesomeIcon
              icon={isShowed ? faCaretDown : faCaretRight}
              className='ml-1 mr-1'
              style={{ cursor: 'pointer' }}
              onClick={e => showOrHideChildren(option.value)}
            />
          </>
        )}
        {!isShowed && childrensSelectedCount !== 0 && (
          <small className='ml-1'>
            {childrensSelectedCount}/{option.childrens.length}
          </small>
        )}
        {option.childrens && isShowed && (
          <Element ml={3}>
            <CheckboxesFilters
              options={option.childrens}
              values={values}
              onChange={onChange}
              level={level + 1}
            />
          </Element>
        )}
      </Form.Control>
    )
  })
}

const ProductsFilters = ({ onChange }) => {
  const [state, actions] = useProducts()

  //Brands state and actions
  const [brandsState, brandsActions] = useBrands()
  const [brandsRequest] = useBrandsRequest({ key: 'get /brands' })
  const brandsFilters = React.useMemo(
    () =>
      sortBy(brandsState.all
        .filter(brand => brand.name !== 'AUTRES' && brand.visibility.filter)
        .map(brand => ({
          value: brand._id,
          label: brand.name,
        })), 'label'),
    [brandsState.all]
  )
  const { language } = useI18next()

  //Categories state and actions
  const [categoriesState, categoriesActions] = useCategories()
  const [categoriesRequest] = useCategoriesRequest({ key: 'get /categories' })
  const [categoriesFilters] = useCategoriesForFilters({ language })

  //Load filters data
  React.useEffect(() => {
    brandsActions.fetch()

    if (!categoriesState.all[0]) categoriesActions.fetch({ mode: 'tree' })
  }, [])

  const setFilter = filter => {
    onChange()
    filter.noSubmit = true
    actions.setFilter(filter)
  }
  const { t } = useTranslation()
  return (
    <>
      <Element mb={5}>
        <Heading size={5} mb={3}>
          {t('products:categories')}
        </Heading>
        <ResourceStatusHandler
          request={categoriesRequest}
          statuses={{
            error: 'Echec',
            inProgress: 'loading',
          }}
        >
          <CheckboxesFilters
            options={categoriesFilters}
            onChange={value =>
              setFilter({
                key: 'categories',
                value,
              },
              true)
            }
            values={state.filters.categories}
          />
        </ResourceStatusHandler>
      </Element>
      <Element mb={5}>
        <Heading size={5} mb={3}>
          {t('products:brands')}
        </Heading>
        <ResourceStatusHandler
          request={brandsRequest}
          statuses={{
            error: 'Echec',
            inProgress: 'loading',
          }}
        >
          <CheckboxesFilters
            options={brandsFilters}
            onChange={value => {
              setFilter({
                key: 'brand',
                value,
              })
            }}
            values={state.filters.brand}
          />
        </ResourceStatusHandler>
      </Element>
      <Element mb={5}>
        <Heading size={5} mb={3}>
          {t('products:in_stock')}
        </Heading>
        <ResourceStatusHandler
          request={brandsRequest}
          statuses={{
            error: 'Echec',
            inProgress: 'loading',
          }}
        >
          <CheckboxesFilters
            options={[{ value: 'yes', label: t('products:yes') }]}
            onChange={value => {
              setFilter({
                key: 'inStock',
                value,
              })
            }}
            values={state.filters.inStock}
          />
        </ResourceStatusHandler>
      </Element>

      {/*<Element mb={5}>
        <Heading size={5} mb={3}>
          {t('products:price')}
        </Heading>
        <Element display='flex' justifyContent='space-between' mb={4}>
          <Form.Control>
            <Form.Input type='number' min='0' placeholder={0} defaultValue={0} style={{ width: '4.5em' }} />
          </Form.Control>
          <Form.Control>
            <Form.Input type='number' max='999' placeholder={999} defaultValue={999} style={{ width: '4.5em' }} />
          </Form.Control>
        </Element>
        <DoubleRangeSlider min={1} max={999} />
          </Element> */}
    </>
  )
}

export default ProductsFilters
