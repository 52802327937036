import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import Seo from '../components/seo'
import {
  Button,
  Columns,
  Container,
  Element,
  Icon,
  Pagination,
  Section,
} from 'react-bulma-components'
import SingleProductThumbnail from '../components/e-commerce/single-product-thumbnail'
import { getCategoryAndChildrens, useProducts, useProductsRequest } from '../stores/products'
import ProductsFilters from '../components/products/filters/products-filters'
import { useCategories } from '../stores/categories'
import { useI18next } from 'gatsby-plugin-react-i18next'
import { useLocation } from '@reach/router'
import queryString from 'query-string'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFilter, faFilterCircleXmark, faSpinner } from '@fortawesome/free-solid-svg-icons'
import { useTranslation } from 'gatsby-plugin-react-i18next'

const ProductsPage = () => {
  const [state, actions] = useProducts()
  const [categoriesState] = useCategories()

  const [request] = useProductsRequest({
    key: 'get /products',
  })

  const location = useLocation()
  const urlQuery = queryString.parse(location.search)

  const { t } = useTranslation()

  //TODO: URL PARAM or query?
  const [displayedPerPage] = React.useState(12)
  const [displayFilters, setDisplayFilters] = React.useState(false)
  const [currentPage, setCurrentPage] = React.useState(
    urlQuery.page ? Number(urlQuery.page) : 1
  )
  const pagesCount = React.useMemo(
    () => Math.ceil(state.count / displayedPerPage),
    [state.count]
  )

  const { navigate } = useI18next()

  const changePage = pageNumber => {
    if (pageNumber === currentPage) return
    actions.clearAll()
    navigate(`/all-products/?page=${pageNumber || 1}`, { replace: true })
    setCurrentPage(Number(pageNumber))
  }

  //Fetch products on filter change (get all childrens categories)
  React.useEffect(() => {
    const allCategories = getCategoryAndChildrens(categoriesState.all, [...state.filters.categories])
    actions.fetch({
      ...state.filters,
      categories: allCategories,
      limit: 12,
      offset: !currentPage ? 0 : (currentPage - 1) * displayedPerPage,
    })
  }, [state.filters, currentPage, displayedPerPage])

  React.useEffect(() => {
    changePage(urlQuery.page)
  }, [urlQuery.page])

  const ListPagination = () => (
    <Pagination
      total={pagesCount}
      current={currentPage || 1}
      autoHide={false}
      showFirstLast
      delta={3}
      onChange={pageNumber => changePage(pageNumber)}
      size='small'
      align='center'
    ></Pagination>
  )

  return (
    <Layout>
      <Section>
        <Container>
          <Columns>
            <Columns.Column size={2} mb={5} className='is-hidden-tablet'>
              <Button
                color={'primary'}
                outlined={!displayFilters}
                mb={5}
                onClick={() => setDisplayFilters(!displayFilters)}
                fullwidth
              >
                <Icon>
                  <FontAwesomeIcon icon={displayFilters ? faFilterCircleXmark : faFilter} />
                </Icon>
                <span>
                  {displayFilters
                    ? t('products:hide_filters')
                    : t('products:display_filters')}
                </span>
              </Button>
              {displayFilters && (
                <>
                  {/* START Duplicated code */}
                  <ProductsFilters onChange={() => changePage(1)} />
                  <Element>
                    <Button
                      size='small'
                      mt='2'
                      onClick={() => actions.resetFilters()}
                    >
                      {t('products:reset_filters')}
                    </Button>
                  </Element>
                  {/* END Duplicated code */}
                </>
              )}
            </Columns.Column>
            <Columns.Column size={2} mb={5} className='is-hidden-mobile'>
              {/* START Duplicated code */}
              <ProductsFilters onChange={() => changePage(1)} />
              <Element>
                <Button
                  size='small'
                  mt='2'
                  onClick={() => actions.resetFilters()}
                >
                  {t('products:reset_filters')}
                </Button>
              </Element>
              {/* END Duplicated code */}
            </Columns.Column>
            <Columns.Column ml={4}>
              <Element mb={2}>
                <ListPagination />
              </Element>
              {state.all[0] ? (
                <>
                  <Columns breakpoint='mobile' gap={8}>
                    {state.all.map(product => (
                      <Columns.Column
                        mobile={{ size: 6 }}
                        tablet={{ size: 4 }}
                        desktop={{ size: 3 }}
                        key={product._id}
                      >
                        <SingleProductThumbnail
                          product={product}
                          customPrices={
                            state.allPrices && state.allPrices[product._id]
                          }
                        />
                      </Columns.Column>
                    ))}
                  </Columns>
                  <Element mt={6}>
                    <ListPagination />
                  </Element>
                </>
              ) : (
                <Columns.Column ml={4}>
                  <Element textAlign='center' mt='3'>
                    {request && request.status === 'success' && (
                      <>
                        {t('products:filters_no_match')}
                        <Element>
                          <Button mt='4' onClick={() => actions.resetFilters()}>
                            {t('products:reset_filters')}
                          </Button>
                        </Element>
                      </>
                    )}
                    {!request ||
                      (request.status === 'inProgress' && (
                        <>
                          <Element>{t('products:loading')}</Element>
                          <Element mt='4'>
                            <FontAwesomeIcon icon={faSpinner} size='4x' spin />
                          </Element>
                        </>
                      ))}
                  </Element>
                </Columns.Column>
              )}
            </Columns.Column>
          </Columns>
        </Container>
      </Section>
    </Layout>
  )
}

export default ProductsPage

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
