import { createHook, createStore } from 'react-sweet-state'
import { apiCall } from '../core/store'

// This is the value of the store on initialisation
const initialState = {
  endpoint: '/brands',
  filters: {},
  all: []
}

// All the actions that mutate the store
const actions = {
  fetch: (filters) => async ({ setState, dispatch }) => {
    const res = await dispatch(apiCall({ params: filters }))
    res && res.result && setState({ all: res.result })
  },
  setFilter: ({ key, value }) => async ({ setState, getState, dispatch }) => {
    setState({
      filters: {
        ...getState().filters,
        [key]: value
      }
    })
    dispatch(actions.fetch(getState().filters))
  }
}

const Store = createStore({ initialState, actions, name: 'brands' })

export const useBrandsRequest = createHook(Store, {
  selector: (state, props) => state.requests && state.requests[props.key]
})

export const useBrands = createHook(Store)
